import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { getSnapshot, subscribe } from '@cfra-nextgen-frontend/shared/src/hooks/windowDimensions';
import { ValueTypes, formatValue } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import {
    ColDef,
    Column,
    GridApi,
    IServerSideDatasource,
    ModelUpdatedEvent,
    RowHeightParams,
    SortChangedEvent,
    ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useSyncExternalStore,
} from 'react';
import './AgGrid.scss';

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

export type AgGridProps = {
    columnDefs: Array<ColumnDef>;
    rowsData?: Array<any>;
    getResizableMinWidthForColumn: (key: string) => number;
    updateCustomColumnsWidths?: (gridRef: React.RefObject<AgGridReact>) => void;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number; // the value in percentage from window height [1;100], needed for cases when grid container is centered, for example if grid is placed inside modal window
    useSSRMode?: boolean;
    SSRDataSource?: IServerSideDatasource;
    SSRrowsToFetch?: number;
    rowMultiSelectWithClick?: boolean;
    rowSelection?: 'single' | 'multiple';
    getRowID?: (params: any) => string;
    suppressRowClickSelection?: boolean;
    useAutoHeight?: boolean;
    gridTheme?: string;
    defaultRowHeight?: number;
    headerHeight?: number;
    defaultMaxWidth?: number | null;
    unlimitedCalculatedHeight?: boolean;
    rowsGap?: number;
    lastRowAdditionalWidth?: number; // needed to not cut off shadow for last row;
    horizontalScrollbarAreaHeight?: number;
    customFlexibleColumns?: Array<string>;
    getRowHeight?: (params: RowHeightParams) => number | undefined | null;
    enableAutoSizeAllColumns?: boolean;
    onFirstDataRenderedRef?: React.MutableRefObject<() => void>;
    onModelUpdatedRef?: React.MutableRefObject<(event: ModelUpdatedEvent) => void>;
    onSortChangedRef?: React.MutableRefObject<(event: SortChangedEvent) => void>;
    fullHeightGrid?: boolean;
};

export const defaultMinWidth = 120;

type SortState = {
    colId: string;
    sort: 'asc' | 'desc' | null;
};

export const AgGrid = forwardRef<AgGridReact | null, AgGridProps>((props, ref) => {
    const { onRowSelected, setGridRef } = useContext(AgGridSelectedRowsContext);
    const { height } = useSyncExternalStore(subscribe, getSnapshot);
    const [gridTopPosition, setGridTopPosition] = useState<number>();
    const gridContainerRef = useRef<HTMLDivElement>(null);
    const [gridApi, setGridApi] = useState<GridApi<any>>();
    const [gridHeight, setGridHeight] = useState<number>(0);

    useEffect(() => {
        const gridContainerRefCurrent = gridContainerRef.current;
        if (gridContainerRefCurrent) {
            const { top } = gridContainerRefCurrent.getBoundingClientRect();
            setGridTopPosition(top);
        }
    }, [gridContainerRef, props]);

    const {
        rowsData = [],
        getResizableMinWidthForColumn,
        updateCustomColumnsWidths,
        maxNumberOfRowsToDisplay = 20,
        maxGridContainerHeightPercentage,
        useSSRMode = false,
        useAutoHeight = false,
        gridTheme,
        defaultRowHeight = 46,
        headerHeight,
        defaultMaxWidth = 350,
        unlimitedCalculatedHeight,
        rowsGap,
        lastRowAdditionalWidth,
        horizontalScrollbarAreaHeight = 18,
        customFlexibleColumns,
        getRowHeight,
        enableAutoSizeAllColumns = true,
        onFirstDataRenderedRef,
        onModelUpdatedRef,
        onSortChangedRef,
        fullHeightGrid,
    } = props;

    const gridRef = useRef<AgGridReact>(null);

    const calculateHeight = useCallback(() => {
        const windowHeight = height || 0;

        let rowsToDisplay: number = 0;

        if (useSSRMode) {
            rowsToDisplay = props.SSRrowsToFetch || 0;
        } else {
            rowsToDisplay = fullHeightGrid && gridApi ? gridApi?.getModel()?.getRowCount?.() : rowsData.length;
        }

        const numberOfRowsToShow = rowsToDisplay > maxNumberOfRowsToDisplay ? maxNumberOfRowsToDisplay : rowsToDisplay; // show vertical scroll if more than maxNumberOfRowsToDisplay rows

        const tableBodyHeight =
            horizontalScrollbarAreaHeight +
            numberOfRowsToShow * defaultRowHeight +
            (rowsGap || 0) * (numberOfRowsToShow - 1) +
            (lastRowAdditionalWidth || 0);

        const staticGridHeight = headerHeight ? tableBodyHeight + headerHeight : tableBodyHeight + defaultRowHeight;

        const _gridTopPosition = gridTopPosition || 0;

        if (maxGridContainerHeightPercentage) {
            const maxGridContainerHeight = (windowHeight * maxGridContainerHeightPercentage) / 100;
            const gridContainerSpaceAboveTheGrid = _gridTopPosition - (windowHeight - maxGridContainerHeight) / 2;
            const maxGridHeight = maxGridContainerHeight - gridContainerSpaceAboveTheGrid;

            return staticGridHeight < maxGridHeight ? staticGridHeight : maxGridHeight;
        }

        if (unlimitedCalculatedHeight) {
            return staticGridHeight;
        }

        return staticGridHeight < windowHeight - _gridTopPosition ? staticGridHeight : windowHeight - _gridTopPosition;
    }, [
        height,
        maxNumberOfRowsToDisplay,
        rowsData,
        useSSRMode,
        props.SSRrowsToFetch,
        defaultRowHeight,
        headerHeight,
        rowsGap,
        lastRowAdditionalWidth,
        horizontalScrollbarAreaHeight,
        gridTopPosition,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
        gridApi,
        fullHeightGrid,
    ]);

    const updateGridHeight = useCallback(() => {
        const calculatedHeight = calculateHeight();
        const minGridHeight =
            5 * defaultRowHeight + // 4 rows + header
            horizontalScrollbarAreaHeight;
        const gridHeight = calculatedHeight < minGridHeight ? minGridHeight : calculatedHeight;

        setGridHeight(gridHeight);
    }, [calculateHeight, defaultRowHeight, horizontalScrollbarAreaHeight]);

    useEffect(() => {
        updateGridHeight();
    }, [updateGridHeight]);

    useImperativeHandle(ref, () => gridRef.current!);

    const sortState = useMemo(
        () =>
            props.columnDefs
                .filter((colDef) => colDef.field && colDef.sort) // get only the columns that have sort enabled
                .map((colDef) => ({ colId: colDef.field!, sort: colDef.sort! })), // add sort to column state;
        [props.columnDefs],
    );

    const columnDefs = useMemo(
        () =>
            props.columnDefs?.map((obj) => ({
                minWidth: getResizableMinWidthForColumn(String(obj.headerName)),
                ...obj,
                sort: null, // remove sort from columnDefs, to avoid grid sort issues, it will be applied in onGridColumnsChanged
            })),
        [getResizableMinWidthForColumn, props.columnDefs],
    );

    useEffect(() => {
        if (!gridRef.current) return;
        gridRef.current?.api?.setColumnDefs(columnDefs);
    }, [columnDefs, gridRef.current]); // keep gridRef.current in dependencies to avoid empty grid on navigate to page

    useEffect(() => {
        if (!gridRef.current) return;
        gridRef.current?.columnApi?.applyColumnState({ state: sortState });
    }, [sortState, gridRef.current]); // keep gridRef.current in dependencies to avoid empty grid on navigate to page

    const firstRenderColumnDefs = useMemo(() => columnDefs, []); // keep here no dependencies, the firstRenderColumnDefs should update only first time

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo((): ColDef => {
        const colDef = {
            sortable: true,
            filter: true,
            resizable: true,
            autoHeight: true,
            menuTabs: [],
        };

        return {
            ...colDef,
            ...(defaultMaxWidth && { maxWidth: defaultMaxWidth }),
        };
    }, [defaultMaxWidth]);

    function setMaxResizeWidthForAllColumnsTo(maxWidth: number) {
        const columnDefsLocal: Array<ColDef> = gridRef.current?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefsLocal) return;
        gridRef.current?.api.setColumnDefs(columnDefsLocal.map((columnDef) => ({ ...columnDef, maxWidth: maxWidth })));
    }

    const setMinResizeWidthForAllColumns = useCallback(() => {
        const columnDefsLocal = gridRef.current?.api.getColumnDefs();
        if (!columnDefsLocal) return;
        gridRef.current?.api.setColumnDefs(
            columnDefsLocal.map((columnDef) => ({
                ...columnDef,
                minWidth: getResizableMinWidthForColumn(String(columnDef.headerName)),
            })),
        );
    }, [getResizableMinWidthForColumn]);

    const setInitiallColumnDefsForAllColumns = useCallback(
        (keys: Array<string>) => {
            const localColumnDefs: Array<ColumnDef> = gridRef.current?.api.getColumnDefs() as Array<ColumnDef>;
            if (!localColumnDefs) return;
            gridRef.current?.api.setColumnDefs(
                localColumnDefs.map((localColumnDef) => {
                    keys.forEach((key) => {
                        const colDefProp = columnDefs.filter((item) => item.field === localColumnDef.field)[0];

                        localColumnDef = {
                            ...localColumnDef,
                            [key]:
                                colDefProp && colDefProp.hasOwnProperty(key)
                                    ? colDefProp[key as keyof typeof colDefProp]
                                    : defaultColDef[key as keyof typeof defaultColDef],
                        };
                    });
                    return localColumnDef;
                }),
            );
        },
        [columnDefs, defaultColDef],
    );

    function calculateAllColumnsWidths() {
        return (
            gridRef.current?.columnApi
                .getColumns()
                ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0) || 0
        );
    }

    const autoSizeAllColumns = useCallback(() => {
        if (!gridRef.current || !columnDefs || columnDefs.length === 0 || !enableAutoSizeAllColumns) return;
        const keys = ['maxWidth', 'minWidth'];

        setInitiallColumnDefsForAllColumns(keys);

        const allColumnIds: Array<Column> = [];
        gridRef.current?.columnApi.getColumns()?.forEach((column) => {
            const colDef = column.getColDef();

            if (!customFlexibleColumns || !(colDef?.headerName && customFlexibleColumns.includes(colDef?.headerName))) {
                allColumnIds.push(column);
                return;
            }
        });

        gridRef.current?.columnApi.autoSizeColumns(allColumnIds, true);

        const hasPinnedColumns = gridRef.current.api
            .getColumnDefs()
            ?.map((colDef) => (colDef as ColumnDef).pinned)
            .some((x: any) => Boolean(x));

        if (!hasPinnedColumns) {
            const right = gridRef.current.api.getHorizontalPixelRange().right;
            const actualWidthExceededViewableWidth = calculateAllColumnsWidths() > right;
            if (actualWidthExceededViewableWidth) {
                gridRef.current?.columnApi.autoSizeColumns(allColumnIds, false);
            }
        }

        if (updateCustomColumnsWidths) {
            updateCustomColumnsWidths(gridRef);
        }

        setMaxResizeWidthForAllColumnsTo(9999); // set "unlimited" resize column width for all columns
        setMinResizeWidthForAllColumns();
    }, [
        setInitiallColumnDefsForAllColumns,
        setMinResizeWidthForAllColumns,
        updateCustomColumnsWidths,
        customFlexibleColumns,
        columnDefs,
        enableAutoSizeAllColumns,
    ]);

    return (
        <div ref={gridContainerRef} style={{ width: '100%' }} className='cfra-ag-grid'>
            <div
                className={['ag-theme-alpine', gridTheme || ''].join(' ')}
                style={{
                    height: useAutoHeight ? undefined : `${gridHeight}px`,
                    width: '100%',
                }}>
                <AgGridReact
                    ref={gridRef}
                    {...(useSSRMode
                        ? {
                              rowModelType: 'serverSide',
                              serverSideDatasource: props.SSRDataSource,
                              cacheBlockSize: props.SSRrowsToFetch,
                              getRowId: props.getRowID,
                              rowMultiSelectWithClick: props.rowMultiSelectWithClick,
                          }
                        : { rowData: rowsData })}
                    columnDefs={firstRenderColumnDefs} // only for first render
                    suppressRowClickSelection={props.suppressRowClickSelection || false}
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true}
                    rowSelection={props.rowSelection || 'single'}
                    getRowHeight={
                        getRowHeight ||
                        function (params) {
                            if (params.node.rowIndex === rowsData.length - 1) {
                                return defaultRowHeight + (lastRowAdditionalWidth || 0);
                            }
                            return defaultRowHeight + (rowsGap || 0);
                        }
                    }
                    getRowStyle={(params) => {
                        return {
                            maxHeight: getRowHeight?.(params) || defaultRowHeight,
                        };
                    }}
                    headerHeight={headerHeight || defaultRowHeight}
                    suppressPropertyNamesCheck={true} // This is not ideal, but see: https://github.com/ag-grid/ag-grid/issues/2320
                    onModelUpdated={(event) => {
                        if (fullHeightGrid) {
                            updateGridHeight();
                        }
                        
                        if (!onModelUpdatedRef?.current) {
                            return;
                        }

                        // need at least 100 timeout to have for grid rows rendered
                        setTimeout(() => onModelUpdatedRef?.current?.(event), 100);
                    }}
                    onGridReady={(params) => {
                        autoSizeAllColumns();

                        if (fullHeightGrid) {
                            setGridApi(params.api);
                        }
                        
                        if (!setGridRef) return;
                        setGridRef(gridRef.current);
                    }}
                    onGridSizeChanged={() => {
                        autoSizeAllColumns();
                    }}
                    onRowDataUpdated={() => {
                        autoSizeAllColumns();
                    }}
                    onGridColumnsChanged={() => {
                        autoSizeAllColumns();
                    }}
                    onFirstDataRendered={() => {
                        onFirstDataRenderedRef?.current?.();
                    }}
                    onSelectionChanged={onRowSelected || (() => {})}
                    tooltipShowDelay={0}
                    domLayout={useAutoHeight ? 'autoHeight' : 'normal'}
                    gridOptions={{
                        getContextMenuItems: function (params) {
                            var defaultItems = params.defaultItems;
                            if (!defaultItems) {
                                return [];
                            }
                            var result = defaultItems.filter((item) => item !== 'export'); // hide export option from context menu
                            return result;
                        },
                    }}
                    suppressDragLeaveHidesColumns // disable remove column by drag and drop it out of grid
                    suppressMultiSort
                    onSortChanged={(event) => {
                        onSortChangedRef?.current(event);
                    }}
                />
            </div>
        </div>
    );
});
